// 获取总设备数、激活设备数、在线设备数
const deviceClassifyUrl = `/gateway/hc-connect/data/statistic/deviceClassify`;
// 获取异常分布数据
const warningUrl = `/gateway/hc-connect/data/statistic/warning`;
//获取区级联动接口
// const areaLinkUrl = `/gateway/hc-space/space/getSpaceLinkList`;
const areaLinkUrl = `/gateway/hc-space/space/getSpaceTreeList`;
// 获取每日告警数量
const recentUrl = `/gateway/hc-connect/data/statistic/recent`;
export { deviceClassifyUrl, warningUrl, areaLinkUrl, recentUrl };
