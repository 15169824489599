<template>
  <div class="page">
    <div class="searchBox">
      <!-- <div class="item" v-for="(item, index) in selectList" :key="index">
        <v-select
          @change="valueChange"
          :label="'选择' + item.title"
          :options="item.list"
          v-model="spaceId"
        />
      </div> -->
      <v-select
        @change="valueChange"
        label="选择园区"
        :options="placeList"
        v-model="spaceId"
      />
    </div>
    <div class="equipmentBox">
      <div class="item">
        <div class="nameType">创建设备数</div>
        <div class="num">{{ deviceClassifyData.createDeviceCnt || 0 }}</div>
      </div>
      <div class="item">
        <div class="nameType">激活设备数</div>
        <div class="num">{{ deviceClassifyData.activeDeviceCnt || 0 }}</div>
      </div>
      <div class="item">
        <div class="nameType">在线设备数</div>
        <div class="num">{{ deviceClassifyData.onlineDeviceCnt || 0 }}</div>
      </div>
    </div>
    <div class="brokenLineBox">
      <div class="title">数据趋势</div>
      <div class="box" ref="charts"></div>
    </div>
    <p>多维分析</p>
    <div class="multidimensionalAnalysisBox">
      <div class="leftBox">
        <div class="title">产品分布</div>
        <div class="list">
          <div class="item" v-for="(item, index) in listL" :key="index">
            <div class="name">{{ index + 1 }}、{{ item.productName }}</div>
            <div class="num">{{ item.cnt }}</div>
            <div class="line">
              <el-progress
                :show-text="false"
                :stroke-width="20"
                :percentage="percentageFormat(item.percent)"
                color="#4E8FFF"
              ></el-progress>
            </div>
            <div class="percentage">{{ item.percent }}</div>
          </div>
          <div class="empty" v-if="listL.length == 0">暂无数据！</div>
        </div>
        <div
          class="button"
          @click="showMore('open', 'left')"
          v-if="listLAll.length > 5 && listL.length == 5"
        >
          <div>展开</div>
          <img src="./img/open.png" alt="" />
        </div>
        <div
          class="button"
          @click="showMore('close', 'left')"
          v-if="listLAll.length > 5 && listL.length > 5"
        >
          <div>收起</div>
          <img src="./img/close.png" alt="" />
        </div>
      </div>
      <div class="rightBox">
        <div class="title">异常分布</div>
        <div class="list">
          <div class="item" v-for="(item, index) in listR" :key="index">
            <div class="name">{{ index + 1 }}、{{ item.content }}</div>
            <div class="num">{{ item.sumCnt }}</div>
            <div class="line">
              <el-progress
                :show-text="false"
                :stroke-width="20"
                :percentage="percentageFormat(item.percent)"
                color="#FFA467"
              ></el-progress>
            </div>
            <div class="percentage">{{ item.percent }}</div>
          </div>
          <div class="empty" v-if="listR.length == 0">暂无数据！</div>
        </div>
        <div
          class="button"
          @click="showMore('open', 'right')"
          v-if="listRAll.length > 5 && listR.length == 5"
        >
          <div>展开</div>
          <img src="./img/open.png" alt="" />
        </div>
        <div
          class="button"
          @click="showMore('close', 'right')"
          v-if="listRAll.length > 5 && listR.length > 5"
        >
          <div>收起</div>
          <img src="./img/close.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  recentUrl,
  deviceClassifyUrl,
  areaLinkUrl,
  warningUrl,
} from "./api.js";
export default {
  name: "overView",
  data() {
    return {
      spaceId: "",
      deviceClassifyData: [],
      selectList: [],
      placeList: [],

      listL: [],
      listLAll: [],
      listR: [],
      listRAll: [],

      option: {
        title: {
          text: "设备每日告警数量统计趋势图",
          x: "center",
          y: "bottom",
          textStyle: {
            color: "rgba(0,0,0,0.8500)",
            fontSize: 18,
          },
          textAlign: "left",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          top: "23%",
          containLabel: true, //grid区域是否包含坐标轴的刻度标签
        },
        xAxis: {
          type: "category", //坐标轴类型。
          boundaryGap: false, //坐标轴两边留白策略
          // data: ["07.05", "07.06", "07.07", "07.08", "07.09", "07.10", "07.11"],
          data: [],
          axisLabel: {
            //坐标轴刻度标签的相关设置
            interval: 0,
            textStyle: {
              color: "rgba(0,0,0,0.5000)",
              fontSize: 16,
            },
          },
          axisLine: {
            //坐标轴轴线相关设置
            show: true,
            lineStyle: {
              color: "rgb(213, 213, 213)",
            },
          },
          axisTick: {
            //坐标轴刻度相关设置。
            show: false,
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            //x轴的坐标文字
            show: true,
            textStyle: {
              color: "rgba(0,0,0,0.5000)", //文字的颜色
              fontSize: 16,
            },
          },
          axisLine: {
            //坐标轴轴线相关设置
            show: true,
            lineStyle: {
              color: "rgb(213, 213, 213)",
            },
          },
          axisTick: {
            //坐标轴刻度相关设置。
            show: false,
          },
          splitLine: {
            //坐标在grid区域的分割线
            lineStyle: {
              type: "dashed",
              //设置分割线的样式(图表横线颜色)
              color: ["#e6e6e6"],
            },
          },
        },
        series: [
          {
            smooth: false, //折线平滑
            symbol: "none", //取消折点圆圈
            type: "line",
            // data: [350, 850, 550, 1100, 450, 1500, 300],
            data: [],
            lineStyle: {
              color: "#FE9514", //线的颜色
            },
            areaStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0.1,
                      color: "rgba(254, 149, 20, 0.2)", // 0% 处的颜色
                    },
                    {
                      offset: 0.5,
                      color: "rgba(254, 149, 20, 0.2)", // 0% 处的颜色
                    },
                    {
                      offset: 0.7,
                      color: "rgba(254, 149, 20, 0.1)", // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "rgba(254, 149, 20, 0.05)", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
          },
        ],
      },
    };
  },
  created() {},
  async mounted() {
    let id = this.$store.state.app.userInfo.tenantId;

    this.getDeviceClassifyData(id);
    await this.getItemData();
    await this.getStatisticList(id);
    await this.getWarningList(id);
    await this.getRecentList(id);
    // this.$nextTick(() => {

    // });
  },
  methods: {
    //获取每日告警数量
    async getRecentList(id) {
      let sId = id || this.spaceId;
      let params = {
        spaceId: sId,
      };
      let res = await this.$axios.get(`${recentUrl}`, { params: params });
      if (res.code === 200) {
        console.log(res.data);
        let date = [];
        let data = [];
        res.data.forEach((item) => {
          date.push(item.date);
          data.push(item.sumCnt);
        });
        this.option.xAxis.data = date;
        this.option.series[0].data = data;
      }
      this.mycharts();
    },
    mycharts() {
      let myChart = echarts.init(this.$refs.charts, "macarons");
      myChart.setOption(this.option);
      //图表自适应
      window.addEventListener("resize", function () {
        myChart.resize(); // myChart 是实例对象
      });
    },
    //展开/收起
    showMore(type1, type2) {
      if (type2 == "right") {
        if (type1 == "open") {
          let list = this.listRAll;
          this.listR = list;
        } else {
          let list = this.listRAll;
          this.listR = list.slice(0, 5);
        }
      } else if (type2 == "left") {
        if (type1 == "open") {
          let list = this.listLAll;
          this.listL = list;
        } else {
          let list = this.listLAll;
          this.listL = list.slice(0, 5);
        }
      }
    },
    percentageFormat(str) {
      let newStr = str.slice(0, str.length - 1);
      return Number(newStr);
    },
    // 异常分布列表
    async getWarningList(id) {
      let sId = id || this.spaceId;
      let params = {
        spaceId: sId,
      };
      let res = await this.$axios.get(`${warningUrl}`, { params: params });
      if (res.code === 200) {
        this.listRAll = res.data;
        if (res.data.length > 5) {
          this.listR = res.data.slice(0, 5);
        } else {
          this.listR = res.data;
        }
      }
    },
    //产品分布列表
    async getStatisticList(id) {
      let sId = id || this.spaceId;
      let res = await this.$axios.get(
        `/gateway/hc-device/iot/device/statistic/${sId}/devices`
      );
      if (res.code === 200) {
        this.listLAll = res.data;
        if (res.data.length > 5) {
          this.listL = res.data.slice(0, 5);
        } else {
          this.listL = res.data;
        }
      }
    },
    valueChange(value) {
      this.getDeviceClassifyData();
      this.getStatisticList();
      this.getWarningList();
      // this.getItemData(value);
      this.getRecentList();
    },
    // 筛选条件
    async getItemData(parentId, userTypeId) {
      let params = {
        // parentId: parentId || this.$store.state.app.userInfo.tenantId,
        // specifyCollectionTypes: userTypeId || "10",
        spaceTypes: "10,30",
      };
      let res = await this.$axios.get(`${areaLinkUrl}`, {
        params: params,
      });
      if (res.code === 200) {
        console.log(res);
        res.data.forEach((item) => {
          item.value = item.id;
          item.label = item.name;
        });
        this.placeList = res.data;
        // if (Object.getOwnPropertyNames(res.data).length == 0) {
        //   return;
        // } else {
        //   res.data.list.forEach((item) => {
        //     item.value = item.code;
        //     item.label = item.text;
        //   });
        //   this.selectList.push(res.data);
        //   console.log(this.selectList);
        // }
      }
    },
    // 设备数
    async getDeviceClassifyData(id) {
      let params = {
        spaceId: id || this.spaceId,
      };
      let res = await this.$axios.get(`${deviceClassifyUrl}`, {
        params: params,
      });
      this.deviceClassifyData = res.data;
    },
  },
};
</script>

<style lang="less" scoped>
.page::-webkit-scrollbar {
  display: none;
}

.page {
  box-sizing: border-box;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 150px;
  overflow-y: auto;
  height: 100vh;

  .multidimensionalAnalysisBox {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    .leftBox,
    .rightBox {
      width: 49%;
      background: #ffffff;
      border-radius: 15px;
      padding: 44px 47px 41px 39px;
      box-sizing: border-box;
      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 86px;
        div {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.5);
          margin-right: 8px;
        }
        img {
          width: 14px;
        }
      }
      .title {
        font-size: 18px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        padding-bottom: 59px;
      }
      .list {
        .empty {
          text-align: center;
        }
        .item {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 42px;
          .name {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
            margin-right: 39px;
          }
          .num {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.5);
          }
          .percentage {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
          }
          .line {
            flex: 1;
            height: 20px;
            border-radius: 5px;
            overflow: hidden;
            margin-left: 21px;
            margin-right: 61px;
            /deep/.el-progress-bar__outer {
              background-color: #ffffff !important;
              border-radius: 5px !important;
            }
            /deep/ .el-progress-bar__inner {
              border-radius: 5px !important;
            }
          }
        }
        .item:nth-last-child(1) {
          margin-bottom: 0px;
        }
      }
    }
  }

  p {
    padding: 40px 40px 29px 40px;
    font-size: 18px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    box-sizing: border-box;
  }

  .brokenLineBox {
    padding: 20px 50px 50px 50px;
    box-sizing: border-box;
    width: 100%;
    height: 580px;
    background: #ffffff;
    border-radius: 15px;
    position: relative;
    .box {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      width: 90%;
      padding-bottom: 30px;
    }
    .title {
      position: absolute;
      top: 43px;
      left: 40px;
      font-size: 18px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .searchBox {
    height: 90px;
    background: #ffffff;
    border-radius: 15px;
    padding: 25px 40px;
    box-sizing: border-box;
    margin-bottom: 30px;
    display: flex;
  }

  .equipmentBox {
    width: 100%;
    height: 160px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    .item {
      width: 30%;
      height: 100%;
      background: #ffffff;
      border-radius: 15px;
      padding: 40px;
      box-sizing: border-box;

      .nameType {
        font-size: 18px;
        color: rgba(0, 0, 0, 0.85);
        padding-bottom: 14px;
      }

      .num {
        font-size: 28px;
        font-weight: 600;
        color: rgba(47, 124, 255, 0.85);
      }
    }
  }
}
</style>
